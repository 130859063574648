<div class="order-status-form">
  <h4 mat-dialog-title>{{ 'ORDERS_PAGE.CANCEL_ORDER.TITLE' | translate }}</h4>
  <loader size="md" *ngIf="isLoading" [loading]="isLoading" class="loader"></loader>
  <div *ngIf="!isLoading">
    <div mat-dialog-content>
      <form [formGroup]="cancelOrderStatusForm">
        <mat-form-field>
          <mat-label>{{ 'ORDERS_PAGE.CANCEL_ORDER.NOTES' | translate }}</mat-label>
          <textarea matInput formControlName="notes" placeholder="سبب الإلغاء"></textarea>
        </mat-form-field>
      </form>
    </div>
    <div mat-dialog-actions>
      <button
        mat-raised-button
        color="primary"
        (click)="updateStatus()"
        [disabled]="cancelOrderStatusForm.invalid"
      >
        {{ 'ORDERS_PAGE.CANCEL_ORDER.CONFIRM' | translate }}
      </button>
    </div>
  </div>
</div>
