import { NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import { CancelOrderModel } from 'src/app/core/domain/order/cancel-order.model';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { CancelOrderUseCase } from 'src/app/core/usecases/order/cancel-order.usecase';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { TAAGER_CANCELLED_STATUS } from '../../shared/constants/order-statuses';

@Component({
  selector: 'app-cancel-order-dialog',
  templateUrl: './cancel-order-dialog.component.html',
  styleUrls: ['./cancel-order-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    TranslateModule,
    LoaderComponent,
    NgIf,
  ],
})
export class CancelOrderDialogComponent implements OnInit {
  public cancelOrderStatusForm: UntypedFormGroup;

  public reqObj = {} as CancelOrderModel;

  public isLoading = false;

  constructor(
    private toastr: ToastrService,
    private _cancelOrderUseCase: CancelOrderUseCase,
    private dilogRef: MatDialogRef<CancelOrderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    private _translateService: TranslateService,
  ) {
    this.cancelOrderStatusForm = new UntypedFormGroup({
      notes: new UntypedFormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.reqObj.orders = [];
  }

  updateStatus() {
    this.isLoading = true;
    this.reqObj.notes = this.cancelOrderStatusForm.value.notes;
    this.reqObj.status = TAAGER_CANCELLED_STATUS;
    this.reqObj.orders.push(this.data.order);
    this._logMixpanelEventUseCase.execute({
      eventName: 'Order_canceled',
      payload: {
        'Order Id': this.data.order.orderID,
        Status: this.data.order.status,
        Reasoning: this.cancelOrderStatusForm.value.notes,
        'Shipping Company':
          this.data.order.shippingInfo && this.data.order.shippingInfo.company
            ? this.data.order.shippingInfo.company
            : 'No company',
      },
    });
    this._cancelOrderUseCase
      .execute(this.reqObj)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.dilogRef.close({ status: TAAGER_CANCELLED_STATUS });
          this.toastr.success(
            this._translateService.instant('ORDERS_PAGE.ORDER_CANCELLED_SUCCESS'),
          );
        },
        error: () => {},
      });
  }
}
