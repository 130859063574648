<div mat-dialog-content>
  <div class="title">
    <div class="unit">
      <div class="lab-title">{{ 'ORDERS_PAGE.ORDER_CODE' | translate }}</div>
      <div *ngIf="data.displayIDsInTable" class="lab-value">{{ data.order.orderID }}</div>
      <div *ngIf="!data.displayIDsInTable" class="lab-value">{{ data.childOrders[0].orderID }}</div>
    </div>
    <div class="unit web-only">
      <div class="lab-title">{{ 'ORDERS_PAGE.CUSTOMER_NAME' | translate }}</div>
      <div class="lab-value">{{ data.order.receiverName }}</div>
    </div>
    <div class="unit web-only">
      <div class="lab-title">{{ 'ORDERS_PAGE.CUSTOMER_MOBILE_NUMBER' | translate }}</div>
      <div class="lab-value">{{ data.order.phoneNumber }}</div>
    </div>
    <div class="date-title web-only">
      {{ 'ORDERS_PAGE.ORDER_STATUS' | translate }}
    </div>
    <button
      *ngIf="data.displayIDsInTable"
      class="btn status-badge"
      [ngClass]="{
        received: data.statusStage === 'received',
        delivered: data.statusStage === 'delivered',
        cancelled: data.statusStage === 'cancelled',
        pending: data.statusStage === 'pending'
      }"
    >
      {{ returnOrderStatus(data.order.status) | translate }}
    </button>
    <button
      *ngIf="!data.displayIDsInTable"
      class="btn status-badge"
      [ngClass]="{
        received: data.statusStage === 'received',
        delivered: data.statusStage === 'delivered',
        cancelled: data.statusStage === 'cancelled',
        pending: data.statusStage === 'pending'
      }"
    >
      {{ returnOrderStatus(data.childOrders[0].status) | translate }}
    </button>
  </div>

  <div>
    <div class="inline-div">
      <img loading="lazy" src="../../../assets/img/prods-i.png" class="icon" />
      <div class="title-text">
        {{ 'ORDERS_PAGE.REPLACEMENTS_RETURNS_BTN_LABEL' | translate }}
      </div>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col" *ngIf="data.displayIDsInTable">
            {{ 'ORDERS_PAGE.ORDER_CODE' | translate }}
          </th>
          <th scope="col" colspan="2" class="product-name">
            {{ 'ORDERS_PAGE.ORDER_DETAILS_DIALOG.PRODUCT_NAME' | translate }}
          </th>
          <th scope="col">{{ 'ORDERS_PAGE.ORDER_DETAILS_DIALOG.PRODUCT_QUANTITY' | translate }}</th>
          <th scope="col">{{ 'ORDERS_PAGE.ORDER_TYPE' | translate }}</th>
          <th scope="col">{{ 'ORDERS_PAGE.ORDER_CREATION_DATE' | translate }}</th>
          <th scope="col">{{ 'ORDERS_PAGE.ORDER_STATUS' | translate }}</th>
          <th scope="col">{{ 'ORDERS_PAGE.ORDER_LAST_UPDATE_DATE' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="table-active" *ngFor="let childOrder of data.childOrders; index as idx">
          <th *ngIf="data.displayIDsInTable">{{ childOrder.orderID }}</th>
          <td>
            <div *ngIf="Products[idx]" class="image-container web-only">
              <img loading="lazy" class="product-image" [src]="Products[idx].productPicture" />
            </div>
          </td>
          <th scope="row" *ngIf="Products[idx]">
            {{ Products[idx].productName }}
            <div class="product-variants">
              <span *ngIf="Products[idx].productColor" class="color-variant">
                <span
                  >{{ 'ORDERS_PAGE.ORDER_DETAILS_DIALOG.PRODUCT_COLOR' | translate }}:&nbsp;</span
                >
                <span
                  [ngStyle]="{ backgroundColor: Products[idx].productColorHex }"
                  class="color-ball"
                ></span>
                <span>&nbsp;{{ Products[idx].productColor }}</span>
              </span>
              <span *ngIf="Products[idx].productSize" class="size-variant"
                >{{ 'ORDERS_PAGE.ORDER_DETAILS_DIALOG.PRODUCT_SIZE' | translate }}:
                {{ Products[idx].productSize }}</span
              >
            </div>
          </th>
          <td>{{ OrderProductQuantities[idx] }}</td>
          <td>{{ getChildOrderType(childOrder.orderID) | translate }}</td>
          <td>{{ convertDate(childOrder.createdAt) }}</td>
          <td>{{ getChildOrderStatus(childOrder.status) | translate }}</td>
          <td>{{ convertDate(childOrder.updatedAt) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
