import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
// @ts-ignore
import * as variants from '@taager-experience-shared/variants';
import { ORDER_STATUSES } from 'src/app/presentation/shared/constants';
import { ProductService } from 'src/app/presentation/shared/services/product.service';

@Component({
  selector: 'app-child-order-item-dialog',
  templateUrl: './child-order-item-dialog.component.html',
  styleUrls: ['./child-order-item-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogModule, NgIf, NgClass, NgFor, NgStyle, TranslateModule],
})
export class ChildOrderItemDialogComponent implements OnInit {
  public OrderProds: any;

  public OrderProductPrices: any;

  public OrderProductQuantities: any;

  public Products: any = [];

  constructor(private productService: ProductService, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.OrderProds = this.data.childOrders.reduce((acc: Array<string>, childOrder: any) => {
      acc.push(childOrder.product.productObjectId);
      return acc;
    }, []);
    this.OrderProductPrices = this.data.childOrders.reduce(
      (acc: Array<string>, childOrder: any) => {
        acc.push(childOrder.product.productPrice);
        return acc;
      },
      [],
    );
    this.OrderProductQuantities = this.data.childOrders.reduce(
      (acc: Array<string>, childOrder: any) => {
        acc.push(childOrder.product.productQty);
        return acc;
      },
      [],
    );
    this.getProducts();
  }

  returnOrderStatus(recievedStatus: string): string {
    const statusObject = ORDER_STATUSES.ALL_STATUSES.filter(
      (status) => status.statusInEnglish === recievedStatus,
    );
    return statusObject.length === 0 ? recievedStatus : statusObject[0].statusTranslationKey;
  }

  getChildOrderStatus(childOrderStatus: string): string {
    const statusObject = ORDER_STATUSES.ALL_STATUSES.filter(
      (status) => status.statusInEnglish === childOrderStatus,
    );
    return statusObject.length === 0 ? childOrderStatus : statusObject[0].statusTranslationKey;
  }

  getChildOrderType(childOrderId: string): string {
    return ORDER_STATUSES.CHILD_ORDER_TYPE_MAP.get(childOrderId.charAt(0)) || '';
  }

  convertDate(mongo: any): string {
    if (mongo) {
      const date = new Date(mongo);
      return date.toLocaleDateString('en-US');
    }
    return '';
  }

  getProducts(): void {
    this.productService.getProductsByIds(this.OrderProds).subscribe({
      next: async (res: any) => {
        this.Products = res.data.map((product: any) => {
          const productColorHex = product.attributes?.filter(
            (attribute: any) => attribute.type === 'color',
          )[0]?.value;
          return {
            ...product,
            productColorHex,
            productColor: productColorHex && variants.getColorByHexCode(productColorHex).arabicName,
            productSize: product.attributes?.filter(
              (attribute: any) => attribute.type === 'size',
            )[0]?.value,
          };
        });
      },
    });
  }
}
